@import 'app/styles/variables';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$jazzed-cms-primary: mat-palette($jazzed-palette);
$jazzed-cms-accent: mat-palette($jazzed-palette-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$jazzed-cms-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$jazzed-cms-theme: mat-light-theme($jazzed-cms-primary, $jazzed-cms-accent, $jazzed-cms-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($jazzed-cms-theme);

/* You can add global styles to this file, and also import other style files */

@import 'app/styles/general';

@import 'ngx-toastr/toastr';

@import './app/styles/editor.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;

  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// topbar
$top-bar-height: 64px;

// sidenav
$main-sidenav-width: 240px;

// main container
$main-container-margin: 20px;
$main-container-padding: 15px;

// general
$main-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2);

$background-color: #fafafa;
$error: #f44336;
$success: #00a74f;

$border-color: #d8d8d8;

$black: #333232;

$light-primary-text: white;
$dark-primary-text: rgba(black, .87);

$font-family-stylish: 'Fjalla One',
sans-serif;
$font-family-serif: 'Nanum Myeongjo',
serif;

$standard-header-margin-bottom: 20px;
$standard-header-height: 36px;

$jazzed-palette-50: #e0e0e0;
$jazzed-palette-100: #9e9e9e;
$jazzed-palette-200: #757575;
$jazzed-palette-300: #616161;
$jazzed-palette-400: #424242;
$jazzed-palette-500: #212121;
$jazzed-palette-600: #212121;
$jazzed-palette-700: #111;
$jazzed-palette-800: #111;
$jazzed-palette-900: #000;

$jazzed-palette-accent-50: #d9bea7;
$jazzed-palette-accent-100: #bf9a78;
$jazzed-palette-accent-200: #bf9a78;
$jazzed-palette-accent-300: #8c6330;
$jazzed-palette-accent-400: #8c6330;
$jazzed-palette-accent-500: #401e01;
$jazzed-palette-accent-600: #401e01;
$jazzed-palette-accent-700: #331801;
$jazzed-palette-accent-800: #331801;
$jazzed-palette-accent-900: #241801;

$jazzed-palette: (50: $jazzed-palette-50,
  100: $jazzed-palette-100,
  200: $jazzed-palette-200,
  300: $jazzed-palette-300,
  400: $jazzed-palette-400,
  500: $jazzed-palette-500,
  600: $jazzed-palette-600,
  700: $jazzed-palette-700,
  800: $jazzed-palette-800,
  900: $jazzed-palette-900,
  A100: $jazzed-palette-50,
  A200: $jazzed-palette-200,
  A400: $jazzed-palette-200,
  A700: $jazzed-palette-700,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ));

$jazzed-palette-accent: (50: $jazzed-palette-accent-50,
  100: $jazzed-palette-accent-100,
  200: $jazzed-palette-accent-200,
  300: $jazzed-palette-accent-300,
  400: $jazzed-palette-accent-400,
  500: $jazzed-palette-accent-500,
  600: $jazzed-palette-accent-600,
  700: $jazzed-palette-accent-700,
  800: $jazzed-palette-accent-800,
  900: $jazzed-palette-accent-900,
  A100: $jazzed-palette-accent-50,
  A200: $jazzed-palette-accent-200,
  A400: $jazzed-palette-accent-200,
  A700: $jazzed-palette-accent-700,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ));

$loader: 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 100 100\'%3E%3Cpath fill=\'%23000\' d=\'M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50\'%3E%3CanimateTransform attributeName=\'transform\' attributeType=\'XML\' type=\'rotate\' dur=\'1s\' from=\'0 50 50\' to=\'360 50 50\' repeatCount=\'indefinite\' /%3E%3C/path%3E%3C/svg%3E';

/***
  Quill editor variables
***/

$font-family-editor: 'Roboto', Helvetica, sans-serif;
$font-size-editor: 14px;
$line-height-editor: 1.43;
$description-color: #8c8c8c;

@import './variables';

// utils
.tc {
  text-align: center !important;
}

.tl {
  text-align: left !important;
}

.tr {
  text-align: right !important;
}

.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.clear {
  clear: both;

  &:after {
    content: '';

    display: table;

    clear: both;
  }
}

// typography
.stylish-header {
  font-family: $font-family-stylish;
}

.main-header {
  margin-top: 0;

  font-family: $font-family-stylish;
  font-size: 1.8em;
}

a {
  color: $jazzed-palette-accent-300;
  text-decoration: none;

  &:hover,
  &focus {
    color: $jazzed-palette-accent-500;
  }
}

@keyframes
rotate {
  to {
    transform: rotate(360deg);
  }
}

.img-loader {
  padding: 2px;

  border: none !important;

  animation: rotate 1s linear infinite;
}

.no-data {
  padding: 20px;
  margin-bottom: 20px;

  color: $jazzed-palette-300;
  font-size: 14px;

  background: $jazzed-palette-50;
}

// angular materials
.mat-card,
.mat-card:not([class*=mat-elevation-z]) {
  box-sizing: border-box;

  border-radius: 0 !important;
  box-shadow: $main-box-shadow;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-raised-button,
.mat-stroked-button {
  font-family: $font-family-serif;

  border-radius: 0 !important;

  .material-icons {
    position: relative;
    top: -2px;

    font-size: 16px;
  }

  &.button-xl {
    line-height: 50px;
  }
}

.mat-stroked-button:not([disabled]) {
  border-color: $dark-primary-text;

  &.mat-warn {
    border-color: $error;
  }
}

.mat-list-base {
  .mat-list-item {
    font-size: 14px;
  }

  .mat-subheader {
    font-weight: 300;
  }
}

mat-chip {
  font-family: $font-family-serif;
}

// toast from ToastService
.app-toast {
  position: relative;
  pointer-events: auto;

  width: 300px;
  padding: 15px;
  margin: 0 auto 6px;
  overflow: hidden;

  color: #fff;

  background-image: none !important;

  .toast-title {
    margin-bottom: 10px;

    font-family: $font-family-stylish;
  }

  .toast-close-button {
    position: relative;
    top: -.3em;
    right: -.3em;
    float: right;

    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-shadow: 0 1px 0 #fff;
  }
}

.loader-message {
  display: inline-block;
  margin-top: 10px;
  margin-left: 30px;

  color: $jazzed-palette-200;
  font-size: 16px;
  line-height: 1.5;
}

// Dialog
.mat-dialog-title {
  margin-bottom: 0 !important;
}

.mat-dialog-container {
  min-width: 350px !important;

  border-radius: 0 !important;
  box-shadow: none !important;
}

.mat-dialog-actions {
  margin-bottom: -20px !important;

  justify-content: flex-end !important;
}

.mat-dialog-content {
  padding: 15px 24px 15px !important;

  p {
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

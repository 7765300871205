@import './variables';

.external-html-content,
.ql-container,
.ql-editor {
  font-family: $font-family-editor;
  font-size: $font-size-editor;
  line-height: $line-height-editor;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 35px;

    clear: both;

    line-height: $line-height-editor;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  h4,
  h5,
  h6 {
    font-size: 18px;
  }

  p,
  ul,
  ol,
  blockquote {
    margin: 0 0 35px;

    font-size: $font-size-editor !important;
  }

  ul,
  ol {
    padding-left: 15px;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;

    border-left: 5px solid transparent;

    p {
      margin-bottom: 20px;
    }

    cite {
      color: $description-color;
    }
  }

  a {
    color: inherit;
    font-weight: 600;
  }

  figure {
    margin: 0;

    &.wp-block-image {
      margin-bottom: 35px;
    }
  }

  // wordpress classes
  .wp-block-image {
    clear: both;

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    figcaption {
      padding: 5px;

      color: $description-color;
      font-style: italic;
      text-align: center;
    }

    .alignleft,
    .alignright {
      max-width: 370px;
      margin-bottom: 35px;
    }

    .alignleft {
      float: left;

      margin-right: 20px;
    }

    .alignright {
      float: right;

      margin-left: 20px;
    }

    .aligncenter {
      margin-bottom: 35px;

      img {
        margin: 0 auto;
      }
    }

    &.alignwide,
    &.alignfull {
      margin-bottom: 35px;

      img {
        width: 100%;
      }
    }
  }
}
